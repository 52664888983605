import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { simpleAjaxRequest } from "../helpers/simple-ajax-request";
import { setClasses } from "../helpers/set-classes";
import { base64encode } from "../helpers/btoa";
import { urlToDataURL } from "../helpers/url-to-data-url";
import { PUBLISH_BADGE_SUCCESS_64 } from "../images/success-badge";
import Layout from "../components/layout";
import SEO from "../components/seo";
// ? TYPES:
import { AdminDataQuery_allWordpressCategory_edges_node } from "./__generated__/AdminDataQuery";
import { Post } from "../models/post.model";
type SkillPre = AdminDataQuery_allWordpressCategory_edges_node;

const startOfDay = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};
const createCode = (date: Date) =>
  base64encode(base64encode(startOfDay(date).toISOString()));
const CODE_CHECK = createCode(new Date());

const WORDPRESS_URL = "deswolfgatsby.wordpress.com";

const ADMIN_URLS = {
  PUBLISH_URL: process.env.GATSBY_ADMIN_PUBLISH_URL,
  PUBLISH_BADGE:
    "https://api.netlify.com/api/v1/badges/3f7f0023-abc8-4e6e-9434-980f4ea34d4b/deploy-status",
  NEW_POST: "https://wordpress.com/post/" + WORDPRESS_URL,
  EDIT_POST: "https://wordpress.com/post/" + WORDPRESS_URL + "/",
  NEW_CATEGORY:
    "https://" + WORDPRESS_URL + "/wp-admin/edit-tags.php?taxonomy=category",
  EDIT_CATEGORY:
    "https://" + WORDPRESS_URL + "/wp-admin/term.php?taxonomy=category&tag_ID=",
};

const generateBadgeUrl = () =>
  ADMIN_URLS.PUBLISH_BADGE + "?" + new Date().toISOString();

const H2Spacer = () => (
  <h2 style={{ visibility: "hidden", display: "inline-block" }}>.</h2>
);

const AdminPosts = ({ edges }: { edges: { node: Post }[] }) => {
  return (
    <section id="admin">
      <header style={{ textAlign: "right" }}>
        <H2Spacer />
        <a
          className="btn green outlined"
          href={ADMIN_URLS.NEW_POST}
          rel="noopener noreferrer"
          target="_blank"
        >
          New Post
        </a>
      </header>
      {edges.map(({ node }: { node: Post }) => {
        return (
          <div className="admin__post" key={node.wordpress_id}>
            <div>
              <img src={node.featured_media?.source_url} alt="" />
            </div>
            <div style={{ overflow: "auto" }}>
              <h3>{node.title}</h3>
              <span
                className="admin__excerpt"
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              />
              <pre>
                <code>{node.content}</code>
              </pre>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <a
                className="btn orange outlined"
                href={ADMIN_URLS.EDIT_POST + node.wordpress_id}
                rel="noopener noreferrer"
                target="_blank"
              >
                Edit
              </a>
            </div>
          </div>
        );
      })}
    </section>
  );
};

const AdminCategories = ({ edges }: { edges: { node: SkillPre }[] }) => {
  return (
    <section id="admin">
      <header style={{ textAlign: "right" }}>
        <H2Spacer />
        <a
          className="btn green outlined"
          href={ADMIN_URLS.NEW_CATEGORY}
          rel="noopener noreferrer"
          target="_blank"
        >
          New Category
        </a>
      </header>
      {edges.map(({ node }: { node: SkillPre }) => {
        return (
          <div
            className={setClasses("admin__post admin__category", {
              exclude: node.description!.includes("exclude"),
            })}
            key={node.wordpress_id!}
          >
            {/* <div></div> */}
            <div style={{ overflow: "auto" }}>
              <h3>{node.name}</h3>
              <span className="admin__excerpt">
                {parseFloat(node.description as string)}
              </span>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <a
                className="btn orange outlined"
                href={ADMIN_URLS.EDIT_CATEGORY + node.wordpress_id}
                rel="noopener noreferrer"
                target="_blank"
              >
                Edit
              </a>
            </div>
          </div>
        );
      })}
    </section>
  );
};

const AdminPage = () => {
  const data = useStaticQuery(graphql`
    query AdminDataQuery {
      allWordpressPost(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            id
            content
            excerpt
            path
            slug
            status
            sticky
            title
            wordpress_id
            featured_media {
              alt_text
              caption
              date
              id
              media_type
              mime_type
              path
              post
              slug
              source_url
              title
              wordpress_id
            }
          }
        }
      }
      allWordpressCategory(
        filter: { wordpress_parent: { eq: 0 }, wordpress_id: { ne: 1 } }
      ) {
        edges {
          node {
            id
            count
            description
            name
            slug
            wordpress_id
          }
        }
      }
    }
  `);
  const [view, setView] = useState<"POST" | "CATEGORY">("POST");
  const [publishLoading, setPublishLoading] = useState<boolean>(false);
  const [pass, setPass] = useState<string>("");
  const [code, setCode] = useState<string>(
    typeof window !== "undefined" ? localStorage.getItem("code") || "" : ""
  );
  const [badge, setBadge] = useState<string>(generateBadgeUrl());
  let timer: any = null;
  useEffect(() => {
    timer = setTimeout(() => {
      setBadge(generateBadgeUrl());
    }, 60000);
    return (): void => {
      clearTimeout(timer);
    };
  }, []);
  const logOut = () => {
    localStorage.removeItem("code");
    setCode("");
  };
  const checkBadge = async () => {
    const t = await urlToDataURL(generateBadgeUrl());
    if (t === PUBLISH_BADGE_SUCCESS_64) {
      setPublishLoading(false);
      clearTimeout(timer);
    } else {
      timer = setTimeout(() => {
        checkBadge();
      }, 15000);
    }
    setBadge(t);
  };
  const publish = async (): Promise<any> => {
    if (!ADMIN_URLS.PUBLISH_URL) {
      return;
    }
    setPublishLoading(true);
    try {
      const response = await simpleAjaxRequest("POST", ADMIN_URLS.PUBLISH_URL);
    } catch (_e) {
      console.error({ _e });
    }
    timer = setTimeout(() => {
      checkBadge();
    }, 800);
  };
  const submitCode = (_e: any) => {
    const p = {
      year: pass.substring(6, 10) || "2020",
      month: pass.substring(3, 5) || "1",
      day: pass.substring(0, 2) || "1",
    };
    try {
      const c = createCode(
        new Date(
          parseFloat(p.year),
          parseFloat(p.month) - 1,
          parseFloat(p.day),
          0,
          0,
          0,
          0
        )
      );
      setCode(() => {
        localStorage.setItem("code", c);
        return c;
      });
    } catch (_err) {
      //
    }
  };
  const changeCategory = (e: any) => {
    const href = e.currentTarget.href;
    const n = href.lastIndexOf("#!");
    setView(href.substring(n + 2));
    e.preventDefault();
  };
  return (
    <Layout slug="admin">
      <SEO title="Admin" meta={[{ name: "robots", content: "noindex" }]} />
      {CODE_CHECK !== code ? (
        <div className="admin__block notfound__block">
          <h2>Admin</h2>
          <input
            style={{ maxWidth: "300px" }}
            className="input"
            type="text"
            onChange={({ target: { value } }: any) => setPass(value)}
          />
          <button
            className="btn white outline"
            style={{ marginTop: "4.8rem" }}
            onClick={submitCode}
          >
            Log In
          </button>
        </div>
      ) : (
        <div className="admin__block">
          <header>
            <h2>
              <span className={view}>
                <a
                  href="#!POST"
                  onClick={changeCategory}
                  className="name__post"
                >
                  Posts
                </a>
                <a
                  href="#!CATEGORY"
                  onClick={changeCategory}
                  className="name__category"
                >
                  Categories
                </a>
              </span>
              <div className="admin__header">
                <button
                  disabled={publishLoading}
                  className={setClasses("btn netlify", {
                    loading: publishLoading,
                  })}
                  onClick={publish}
                >
                  Publish
                </button>
                <div
                  className="admin__badge"
                  onClick={() => setBadge(generateBadgeUrl())}
                >
                  <img src={badge} alt="" />
                </div>
                <button className="btn white" onClick={logOut}>
                  Log Out
                </button>
              </div>
            </h2>
          </header>
          {view === "POST" ? (
            <AdminPosts edges={data.allWordpressPost.edges} />
          ) : (
            <AdminCategories edges={data.allWordpressCategory.edges} />
          )}
        </div>
      )}
    </Layout>
  );
};

export default AdminPage;
